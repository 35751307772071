<template>
  <div>
    <div style="position:relative;" v-if="getResult && !subscribed">
      <div class="d-flex flex-1 blured-div">
        <div class="wrapper">
          <div class="text-center">
            <h1 class="d-sm-none d-block">Unlock Source Codes with an Upgrade</h1>
            <h1 style="font-size:3rem" class="d-none d-sm-block">Unlock Source Codes with an Upgrade</h1>
            <h4>When you upgrade to Pro, you will be able to access keyword search, niches, source codes and more tools from AppLead.</h4>
          </div>
          <b-button style="font-size:2rem" variant="outline-success" to="/pages/plan-selection">
            Upgrade Now
          </b-button>
        </div>
      </div>
      <div style="filter: blur(3px); " >
        <b-card style="display: grid;">
          <div class="container">
            <div class="row justify-content-between">
              <h3 class="col-sm-5 col-md-7">
                <feather-icon icon="ListIcon" size="20" />
                List of Scripts Availables
              </h3>
              <div class="col-sm-6 col-md-4 d-flex">
                <feather-icon icon="FilterIcon" size="20" class="mr-1" style="margin-top: 7px;" />
                <b-form-select v-model="filterTagName" >
                  <template #first>
                    <b-form-select-option :value="null" selected disabled>--- Filter By Technology
                      ---</b-form-select-option>
                  </template>
                </b-form-select>
                <b-button v-if="filterTagName" variant="outline-danger"  class="py-0 ml-1">
                  <feather-icon icon="XCircleIcon" style="py-0" size="12"></feather-icon>
                </b-button>
              </div>
            </div>
          </div>
        </b-card>
        <div class="container">
          <div class="row mt-3 mb-1" v-if="marketplaceDataDefault.length > 0">
            <div class="col-md-4 col-sm-6" v-for="(code, index) in marketplaceDataDefault" :key="index">
              <b-card-group>
                <b-card no-body style="cursor: pointer; height: 65vh !important;" :img-src="code.image_url" img-alt="Image" img-top position="relative" >

                  <b-card-title style="margin-bottom: 1rem !important; font-weight: 500; font-size: 1.5rem;"
                    class="cardPadding mt-2">
                    {{ code.name }}
                  </b-card-title>
                  <div class="container-fluid mb-1 px-2">
                    <div class="row">

                      <div class="w-auto cardPaddingTag" v-for="(tag, index) in (code.tags)"
                        :key="index">
                        <b-badge pill>
                          {{ tag }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex align-items-center justify-content-between cardPadding ">
                    <h3 class="price text-success">{{ price }}</h3>
                    <b-link>
                      <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success"
                        style="border: none !important;padding:0 !important;">
                        <feather-icon icon="DownloadIcon" size="25" />
                      </b-button>
                    </b-link>
                  </div>
                  <p class="cardPadding mb-1">
                    {{ formatDownloads(code.downloads) }} <feather-icon icon='DownloadIcon' />
                  </p>
                </b-card>
              </b-card-group>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-if="getResult && subscribed">
      <b-card style="display: grid;">
        <div class="container">
          <div class="row justify-content-between">
            <h3 class="col-sm-5 col-md-7">
              <feather-icon icon="ListIcon" size="20" />
              List of Scripts Availables
            </h3>
            <div class="col-sm-6 col-md-4 d-flex">
              <feather-icon icon="FilterIcon" size="20" class="mr-1" style="margin-top: 7px;" />
              <b-form-select value="a" :options="allTagsList">
                <template #first>
                  <b-form-select-option :value="null" selected disabled>--- Filter By Technology
                    ---</b-form-select-option>
                </template>
              </b-form-select>
              <b-button v-if="filterTagName" variant="outline-danger" @click="clearFilter" class="py-0 ml-1">
                <feather-icon icon="XCircleIcon" style="py-0" size="12"></feather-icon>
              </b-button>
            </div>
          </div>
        </div>
      </b-card>
      <div class="loader-overlay" v-if="isLoading">
        <b-spinner label="Loading..." style="width: 6rem; height: 6rem;" variant="primary" />
      </div>
      <div class="container" v-else>
        <div class="row mt-3 mb-1" v-if="marketplaceData.length > 0">
          <div class="col-md-4 col-sm-6" v-for="(code, index) in marketplaceData" :key="index">
            <b-card-group>
              <b-card no-body @click="getDetailCode(code.id)" style="cursor: pointer;" :img-src="code.image_url"
                img-alt="Image" img-top position="relative">

                <b-card-title style="margin-bottom: 1rem !important; font-weight: 500; font-size: 1.5rem;"
                  class="cardPadding mt-2">
                  {{ code.name }}
                </b-card-title>
                <div class="container-fluid mb-1 px-2">
                  <div class="row">

                    <div class="w-auto cardPaddingTag" v-for="(tag, index) in JSON.parse(code.tags)[0].split(',')"
                      :key="index">
                      <b-badge pill>
                        {{ tag }}
                      </b-badge>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="d-flex align-items-center justify-content-between cardPadding ">
                  <h3 class="price text-success">{{ price }}</h3>
                  <b-link>
                    <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success"
                      style="border: none !important;padding:0 !important;">
                      <feather-icon icon="DownloadIcon" size="25" />
                    </b-button>
                  </b-link>
                </div>
                <p class="cardPadding mb-1">
                  {{ formatDownloads(code.downloads) }} <feather-icon icon='DownloadIcon' />
                </p>
              </b-card>
            </b-card-group>
          </div>
        </div>
        <div class="row mt-5 justify-content-center" v-else>
          <div class="col-md-6 col-sm-12">
            <div class="text-center">
              <feather-icon icon="AlertCircleIcon" size="80" />
              <h3 class="my-2" style="font-size: 3rem !important;">No data available yet</h3>
              <p class="h4">We're working on it! Check back soon.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!getResult">
      <LoaderVue />
    </div>
  </div>
</template>
  
<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardGroup,
  BButton,
  BSpinner,
  BFormSelectOption,
  BModal,
  VBModal,
  BCardTitle,
  BFormFile,
  BLink,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import { getAccessTokenFromCookie } from "@/auth/utils";
import LoaderVue from '@/components/Loader/Loader.vue';

export default {
  components: {
    BTable,
    BAvatar,
    'b-tooltip': VBTooltip,
    BCard,
    BCardGroup,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BLink,
    BCol,
    BSpinner,
    BFormGroup,
    BFormSelect,
    BCardTitle,
    BFormFile,
    vSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BFormSelectOption,
    BContainer,
    LoaderVue
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      getResult: null,
      subscribed: null,
      router,
      marketplaceData: [],
      marketplaceDataDefault: [
        {
          "name": "Threads Downloader",
          "image_url": "https://app.applead.io/backend/public/storage/imgScript/1693915465.jpg",
          "tags": ["html", "css", "ionic"],
          "downloads": 100
        },
        {
          "name": "Full Details of Guide For App - Offline Guidebook",
          "image_url": "https://app.applead.io/backend/public/storage/imgScript/1691171053.jpg",
          "tags": ["flutter", "android studio"],
          "downloads": 75
        },



      ]
      ,
      price: 'Free',
      tags: [],
      filterTagName: null,
      allTagsList: [],
      isLoading: false,

    }
  },
  watch: {
    filterTagName(value) {
      if (value) this.getListByTag(value);
      else this.getListCodes();
    },
  },
  mounted() {
    this.checkUserPlan();
    const data = getAccessTokenFromCookie();
    const userType = data[2].role;
    if (userType !== "user") {
      router.push({ name: "error-404" })
    }
    this.getListCodes();
    this.getAllTags();
  },
  created() {
  },
  computed: {

  },
  methods: {
    checkUserPlan() {
      axios.post('/checkUserSubscription').then(({ data }) => {
        this.getResult = true;
        this.subscribed = true;
      }).catch((err) => {
        if (err.status === 403) {
          this.getResult = true;
          this.subscribed = false;
        }
      });
    },
    formatDownloads(downloads) {
      if (downloads >= 1000000) {
        return (downloads / 1000000).toFixed(1) + "M";
      } else if (downloads >= 1000) {
        return (downloads / 1000).toFixed(1) + "K";
      } else {
        return downloads.toString();
      }
    },
    getAllTags() {
      axios.post('/get-all-tags')
        .then(({ data }) => {
          if (data.status == "success") {
            this.allTagsList = data.tags;
          }
        })
    },
    getListByTag(tag) {
      axios.post('/get-mrktp-scripts-by-tag', {
        tag: tag
      })
        .then((response) => {
          this.marketplaceData = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
    },
    clearFilter() {
      this.filterTagName = null;
    },
    goToTagFilterPage(tagName) {
      router.push({ name: "ScriptsByTag", params: { tag: tagName } })
    },
    getDetailCode(parameter) {
      router.push({ name: "marketplaceDetail", params: { id: parameter } });
    },
    getListCodes() {
      this.isLoading = true;
      axios.post('/get-mrktp-scripts')
        .then((response) => {
          this.marketplaceData = response.data.data;
        }).then(() => {
          this.isLoading = false;

        })
        .catch((error) => {
          console.error(error);
        })
    }
  },
}
</script>
  
<style scoped>
.price {
  font-weight: bold;

}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .download-badge {
    position: static;
    margin-bottom: 10px;
  }
}

.cardPadding {
  padding-inline: 1.6rem;
}

.cardPaddingTag {
  padding-left: 0.4rem;
}

.cardPaddingTag:first-child {
  padding-left: 1.3rem;
}

.loader-overlay {

  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  min-height: 300px;
  /* Add blur effect to the background */
}

/* Center the spinner */

.blured-div {
  position: absolute;
  background-color: black;
  opacity: 0.8;
  z-index: 300;
  width: 100% !important;
  height: 100% !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
